/* eslint-disable no-irregular-whitespace */
/* eslint-disable indent */
export const languageOptions=['English','Hindi-हिन्दी',
  "Telugu-తెలుగు",
  'Tamil-தமிழ்',
  'Kannada-ಕೆನಡಾ',
  'Malayalam-മലയാളം',
  'Other Language'];
export const stateList = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
export const districtList = {
  "Andaman and Nicobar Islands": [
    "NICOBAR",
    "NORTH AND MIDDLE ANDAMAN",
    "SOUTH ANDAMAN",
  ],
  "Andhra Pradesh": [
    "ALLURI SITHARAMA RAJU",
    "ANAKAPALLI",
    "ANANTAPUR",
    "ANNAMAYYA",
    "BAPATLA",
    "CHITTOOR",
    "DR B R AMBEDKAR KONASEEMA",
    "EAST GODAVARI",
    "ELURU",
    "GUNTUR",
    "KAKINADA",
    "KRISHNA",
    "KURNOOL",
    "NANDYAL",
    "NTR",
    "PALNADU",
    "PARVATHIPURAM MANYAM",
    "PRAKASAM",
    "SRI POTTI SRIRAMULU NELLORE",
    "SRI SATHYA SAI",
    "SRIKAKULAM",
    "TIRUPATI",
    "VISAKHAPATNAM",
    "VIZIANAGARAM",
    "WEST GODAVARI",
    "YSR KADAPA",
  ],
  "Arunachal Pradesh": [
    "ANJAW",
    "CHANGLANG",
    "DIBANG VALLEY",
    "EAST KAMENG",
    "EAST SIANG",
    "KRA DAADI",
    "KURUNG KUMEY",
    "LEPA RADA",
    "LOHIT",
    "LONGDING",
    "LOWER DIBANG VALLEY",
    "LOWER SIANG",
    "LOWER SUBANSIRI",
    "NAMSAI",
    "PAKKE KESSANG",
    "PAPUM PARE",
    "SHI YOMI",
    "SIANG",
    "TAWANG",
    "TIRAP",
    "UPPER DIBANG VALLEY",
    "UPPER SIANG",
    "UPPER SUBANSIRI",
    "WEST KAMENG",
    "WEST SIANG",
  ],
  Assam: [
    "BAKSA",
    "BARPETA",
    "BISWANATH",
    "BONGAIGAON",
    "CACHAR",
    "CHARAIDEO",
    "CHIRANG",
    "DARRANG",
    "DHEMAJI",
    "DHUBRI",
    "DIBRUGARH",
    "DIMA HASAO",
    "GOALPARA",
    "GOLAGHAT",
    "HAILAKANDI",
    "HOJAI",
    "JORHAT",
    "KAMRUP",
    "KAMRUP METROPOLITAN",
    "KARBI ANGLONG",
    "KARIMGANJ",
    "KOKRAJHAR",
    "LAKHIMPUR",
    "MAJULI",
    "MORIGAON",
    "NAGAON",
    "NALBARI",
    "SIVASAGAR",
    "SONITPUR",
    "SOUTH SALMARA-MANKACHAR",
    "TINSUKIA",
    "UDALGURI",
  ],
  Bihar: [
    "ARARIA",
    "ARWAL",
    "AURANGABAD",
    "BANKA",
    "BEGUSARAI",
    "BHAGALPUR",
    "BHOJPUR",
    "BUXAR",
    "DARBHANGA",
    "EAST CHAMPARAN (MOTIHARI)",
    "GAYA",
    "GOPALGANJ",
    "JAMUI",
    "JEHANABAD",
    "KAIMUR (BHABUA)",
    "KATIHAR",
    "KHAGARIA",
    "KISHANGANJ",
    "LAKHISARAI",
    "MADHEPURA",
    "MADHUBANI",
    "MUNGER (MONGHYR)",
    "MUZAFFARPUR",
    "NALANDA",
    "NAWADA",
    "PATNA",
    "PURNIA (PURNEA)",
    "ROHTAS",
    "SAHARSA",
    "SAMASTIPUR",
    "SARAN",
    "SHEIKHPURA",
    "SHEOHAR",
    "SITAMARHI",
    "SIWAN",
    "SUPAUL",
    "VAISHALI",
    "WEST CHAMPARAN",
  ],
  Chandigarh: ["Chandigarh"],
  Chhattisgarh: [
    "BALOD",
    "BALODA BAZAR",
    "BALRAMPUR",
    "BASTAR",
    "BEMETARA",
    "BIJAPUR",
    "BILASPUR",
    "BHARATPUR MANENDRAGARH CHIRMIRI",
    "DANTEWADA (SOUTH BASTAR)",
    "DHAMTARI",
    "DURG",
    "GARIYABAND",
    "GAURELA-PENDRA-MARWAHI",
    "JANJGIR CHAMPA",
    "JASHPUR",
    "KABIRDHAM (KAWARDHA)",
    "KANKER (NORTH BASTAR)",
    "KHAIRAGARH-CHHUIKHADAN-GANDAI",
    "KONDAGAON",
    "KORBA",
    "KORIYA (KOREA)",
    "MAHASAMUND",
    "MUNGELI",
    "MOHLA MANPUR AMBAGADH CHOWKI",
    "NARAYANPUR",
    "RAIGARH",
    "RAIPUR",
    "RAJNANDGAON",
    "SARANGARH-BILAIGARH",
    "SUKMA",
    "SURAJPUR",
    "SURGUJA",
    "SAKTI",
    "SARANGARH BILAIGARH",
  ],
  "Dadra and Nagar Haveli and Daman and Diu": [
    "DADRA AND NAGAR HAVELI",
    "DAMAN",
    "DIU",
  ],
  Delhi: [
    "CENTRAL DELHI",
    "EAST DELHI",
    "NEW DELHI",
    "NORTH DELHI",
    "NORTH EAST DELHI",
    "NORTH WEST DELHI",
    "SHAHDARA",
    "SOUTH DELHI",
    "SOUTH EAST DELHI",
    "SOUTH WEST DELHI",
    "WEST DELHI",
  ],
  Goa: ["NORTH GOA", "SOUTH GOA"],
  Gujarat: [
    "AHMEDABAD",
    "AMRELI",
    "ANAND",
    "ARAVALLI",
    "BANASKANTHA (PALANPUR)",
    "BHARUCH",
    "BHAVNAGAR",
    "BOTAD",
    "CHHOTA UDAIPUR",
    "DAHOD",
    "DANG (AHWA)",
    "DEVBHOOMI DWARKA",
    "GANDHINAGAR",
    "GIR SOMNATH",
    "JAMNAGAR",
    "JUNAGADH",
    "KHEDA (NADIAD)",
    "KUTCH",
    "MAHISAGAR",
    "MEHSANA",
    "MORBI",
    "NARMADA (RAJPIPLA)",
    "NAVSARI",
    "PANCHMAHAL (GODHRA)",
    "PATAN",
    "PORBANDAR",
    "RAJKOT",
    "SABARKANTHA (HIMMATNAGAR)",
    "SURAT",
    "SURENDRANAGAR",
    "TAPI (VYARA)",
    "VADODARA",
    "VALSAD",
  ],
  Haryana: [
    "AMBALA",
    "BHIWANI",
    "CHARKHI DADRI",
    "FARIDABAD",
    "FATEHABAD",
    "GURUGRAM (GURGAON)",
    "HISAR",
    "JHAJJAR",
    "JIND",
    "KAITHAL",
    "KARNAL",
    "KURUKSHETRA",
    "MAHENDRAGARH",
    "NUH",
    "PALWAL",
    "PANCHKULA",
    "PANIPAT",
    "REWARI",
    "ROHTAK",
    "SIRSA",
    "SONIPAT",
    "YAMUNANAGAR",
  ],
  "Himachal Pradesh": [
    "BILASPUR",
    "CHAMBA",
    "HAMIRPUR",
    "KANGRA",
    "KINNAUR",
    "KULLU",
    "LAHAUL & SPITI",
    "MANDI",
    "SHIMLA",
    "SIRMAUR (SIRMOUR)",
    "SOLAN",
    "UNA",
  ],
  "Jammu and Kashmir": [
    "ANANTNAG",
    "BANDIPORA",
    "BARAMULLA",
    "BUDGAM",
    "DODA",
    "GANDERBAL",
    "JAMMU",
    "KATHUA",
    "KISHTWAR",
    "KULGAM",
    "KUPWARA",
    "POONCH",
    "PULWAMA",
    "RAJOURI",
    "RAMBAN",
    "REASI",
    "SAMBA",
    "SHOPIAN",
    "SRINAGAR",
    "UDHAMPUR",
  ],
  Jharkhand: [
    "BOKARO",
    "CHATRA",
    "DEOGHAR",
    "DHANBAD",
    "DUMKA",
    "EAST SINGHBHUM",
    "GARHWA",
    "GIRIDIH",
    "GODDA",
    "GUMLA",
    "HAZARIBAGH",
    "JAMTARA",
    "KHUNTI",
    "KODERMA",
    "LATEHAR",
    "LOHARDAGA",
    "PAKUR",
    "PALAMU",
    "RAMGARH",
    "RANCHI",
    "SAHIBGANJ",
    "SERAIKELA-KHARSAWAN",
    "SIMDEGA",
    "WEST SINGHBHUM",
  ],
  Karnataka: [
    "BAGALKOT",
    "BALLARI (BELLARY)",
    "BELAGAVI (BELGAUM)",
    "BENGALURU (BANGALORE) RURAL",
    "BENGALURU (BANGALORE) URBAN",
    "BIDAR",
    "CHAMARAJANAGAR",
    "CHIKKABALLAPUR",
    "CHIKKAMAGALURU (CHIKMAGALUR)",
    "CHITRADURGA",
    "DAKSHINA KANNADA",
    "DAVANAGERE",
    "DHARWAD",
    "GADAG",
    "HASSAN",
    "HAVERI",
    "KALABURAGI (GULBARGA)",
    "KODAGU",
    "KOLAR",
    "KOPPAL",
    "MANDYA",
    "MYSURU (MYSORE)",
    "RAICHUR",
    "RAMANAGARA",
    "SHIVAMOGGA (SHIMOGA)",
    "TUMAKURU (TUMKUR)",
    "UDUPI",
    "UTTARA KANNADA (KARWAR)",
    "VIJAYANAGARA",
    "VIJAYAPURA (BIJAPUR)",
    "YADGIR",
  ],
  Kerala: [
    "ALAPPUZHA",
    "ERNAKULAM",
    "IDUKKI",
    "KANNUR",
    "KASARAGOD",
    "KOLLAM",
    "KOTTAYAM",
    "KOZHIKODE",
    "MALAPPURAM",
    "PALAKKAD",
    "PATHANAMTHITTA",
    "THIRUVANANTHAPURAM",
    "THRISSUR",
    "WAYANAD",
  ],
  Ladakh: ["KARGIL", "LEH"],
  Lakshadweep: ["LAKSHADWEEP"],
  "Madhya Pradesh": [
    "AGAR MALWA",
    "ALIRAJPUR",
    "ANUPPUR",
    "ASHOKNAGAR",
    "BALAGHAT",
    "BARWANI",
    "BETUL",
    "BHIND",
    "BHOPAL",
    "BURHANPUR",
    "CHHATARPUR",
    "CHHINDWARA",
    "DAMOH",
    "DATIA",
    "DEWAS",
    "DHAR",
    "DINDORI",
    "GUNA",
    "GWALIOR",
    "HARDA",
    "HOSHANGABAD",
    "INDORE",
    "JABALPUR",
    "JHABUA",
    "KATNI",
    "KHANDWA",
    "KHARGONE",
    "MANDLA",
    "MANDSAUR",
    "MORENA",
    "NARSINGHPUR",
    "NEEMUCH",
    "PANNA",
    "RAISEN",
    "RAJGARH",
    "RATLAM",
    "REWA",
    "SAGAR",
    "SATNA",
    "SEHORE",
    "SEONI",
    "SHAHDOL",
    "SHAJAPUR",
    "SHEOPUR",
    "SHIVPURI",
    "SIDHI",
    "SINGRAULI",
    "TIKAMGARH",
    "UJJAIN",
    "UMARIA",
    "VIDISHA",
  ],
  Maharashtra: [
    "AHMEDNAGAR",
    "AKOLA",
    "AMRAVATI",
    "AURANGABAD",
    "BEED",
    "BHANDARA",
    "BULDHANA",
    "CHANDRAPUR",
    "DHULE",
    "GADCHIROLI",
    "GONDIA",
    "HINGOLI",
    "JALGAON",
    "JALNA",
    "KOLHAPUR",
    "LATUR",
    "MUMBAI CITY",
    "MUMBAI SUBURBAN",
    "NAGPUR",
    "NANDED",
    "NANDURBAR",
    "NASHIK",
    "OSMANABAD",
    "PALGHAR",
    "PARBHANI",
    "PUNE",
    "RAIGAD",
    "RATNAGIRI",
    "SANGLI",
    "SATARA",
    "SINDHUDURG",
    "SOLAPUR",
    "THANE",
    "WARDHA",
    "WASHIM",
    "YAVATMAL",
  ],
  Manipur: [
    "BISHNUPUR",
    "CHANDEL",
    "CHURACHANDPUR",
    "IMPHAL EAST",
    "IMPHAL WEST",
    "JIRIBAM",
    "KAKCHING",
    "KAMJONG",
    "KANGPOKPI",
    "NONEY",
    "PHERZAWL",
    "SENAPATI",
    "TAMENGLONG",
    "TENGNOUPAL",
    "THOUBAL",
    "UKHRUL",
  ],
  Meghalaya: [
    "EAST GARO HILLS",
    "EAST JAINTIA HILLS",
    "EAST KHASI HILLS",
    "EASTERN WEST KHASI HILLS",
    "NORTH GARO HILLS",
    "RI BHOI",
    "SOUTH GARO HILLS",
    "SOUTH WEST GARO HILLS",
    "SOUTH WEST KHASI HILLS",
    "WEST GARO HILLS",
    "WEST JAINTIA HILLS",
    "WEST KHASI HILLS",
  ],
  Mizoram: [
    "AIZAWL",
    "CHAMPHAI",
    "HNAHTHIAL",
    "KHAWZAWL",
    "KOLASIB",
    "LAWNGTLAI",
    "LUNGLEI",
    "MAMIT",
    "SAIHA",
    "SAITUAL",
    "SERCHHIP",
  ],
  Nagaland: [
    "CHUMOUKEDIMA",
    "DIMAPUR",
    "KIPHIRE",
    "KOHIMA",
    "LONGLENG",
    "MOKOKCHUNG",
    "MON",
    "NIULAND",
    "NOKLAK",
    "PEREN",
    "PHEK",
    "SHAMATOR",
    "TSEMINYU",
    "TUENSANG",
    "WOKHA",
    "ZUNHEBOTO",
  ],
  Odisha: [
    "ANGUL",
    "BALANGIR",
    "BALASORE (BALESWAR)",
    "BARGARH (BARAGARH)",
    "BHADRAK",
    "BOUDH (BAUDH)",
    "CUTTACK",
    "DEOGARH (DEBAGARH)",
    "DHENKANAL",
    "GAJAPATI",
    "GANJAM",
    "JAGATSINGHAPUR",
    "JAJPUR",
    "JHARSUGUDA",
    "KALAHANDI",
    "KANDHAMAL",
    "KENDRAPARA",
    "KENDUJHAR (KEONJHAR)",
    "KHORDHA",
    "KORAPUT",
    "MALKANGIRI",
    "MAYURBHANJ",
    "NABARANGPUR",
    "NAYAGARH",
    "NUAPADA",
    "PURI",
    "RAYAGADA",
    "SAMBALPUR",
    "SONEPUR (SUBARNAPUR)",
    "SUNDARGARH",
  ],
  Puducherry: ["KARAIKAL", "MAHE", "PUDUCHERRY", "YANAM"],
  Punjab: [
    "AMRITSAR",
    "BARNALA",
    "BATHINDA",
    "FARIDKOT",
    "FATEHGARH SAHIB",
    "FAZILKA",
    "FEROZEPUR",
    "GURDASPUR",
    "HOSHIARPUR",
    "JALANDHAR",
    "KAPURTHALA",
    "LUDHIANA",
    "MALERKOTLA",
    "MANSA",
    "MOGA",
    "PATHANKOT",
    "PATIALA",
    "RUPNAGAR",
    "SAHIBZADA AJIT SINGH NAGAR (MOHALI)",
    "SANGRUR",
    "NAWANSHAHR (SHAHID BHAGAT SINGH NAGAR)",
    "SRI MUKTSAR SAHIB",
    "TARN TARAN",
  ],
  Rajasthan: [
    "AJMER",
    "ALWAR",
    "BANSWARA",
    "BARAN",
    "BARMER",
    "BHARATPUR",
    "BHILWARA",
    "BIKANER",
    "BUNDI",
    "CHITTORGARH",
    "CHURU",
    "DAUSA",
    "DHOLPUR",
    "DUNGARPUR",
    "HANUMANGARH",
    "JAIPUR",
    "JAISALMER",
    "JALORE",
    "JHALAWAR",
    "JHUNJHUNU",
    "JODHPUR",
    "KARAULI",
    "KOTA",
    "NAGAUR",
    "PALI",
    "PRATAPGARH",
    "RAJSAMAND",
    "SAWAI MADHOPUR",
    "SIKAR",
    "SIROHI",
    "SRI GANGANAGAR",
    "TONK",
    "UDAIPUR",
  ],
  Sikkim: [
    "GANGTOK (EAST SIKKIM)",
    "GYALSHING (WEST SIKKIM)",
    "MANGAN (NORTH SIKKIM)",
    "NAMCHI (SOUTH SIKKIM)",
    "PAKYONG",
    "SORENG",
  ],
  "Tamil Nadu": [
    "ARIYALUR",
    "CHENGALPATTU",
    "CHENNAI",
    "COIMBATORE",
    "CUDDALORE",
    "DHARMAPURI",
    "DINDIGUL",
    "ERODE",
    "KALLAKURICHI",
    "KANCHEEPURAM",
    "KANNIYAKUMARI",
    "KARUR",
    "KRISHNAGIRI",
    "MADURAI",
    "MAYILADUTHURAI",
    "NAGAPATTINAM",
    "NAMAKKAL",
    "NILGIRIS",
    "PERAMBALUR",
    "PUDUKKOTTAI",
    "RAMANATHAPURAM",
    "RANIPET",
    "SALEM",
    "SIVAGANGAI",
    "TENKASI",
    "THANJAVUR",
    "THENI",
    "THOOTHUKKUDI",
    "TIRUCHIRAPPALLI",
    "TIRUNELVELI",
    "TIRUPATTUR",
    "TIRUPPUR",
    "TIRUVALLUR",
    "TIRUVANNAMALAI",
    "TIRUVARUR",
    "VELLORE",
    "VILLUPURAM",
    "VIRUDHUNAGAR",
  ],
  Telangana: [
    "ADILABAD",
    "BHADRADRI KOTHAGUDEM",
    "HYDERABAD",
    "JAGTIAL",
    "JANGAON",
    "JAYASHANKAR BHUPALPALLY",
    "JOGULAMBA GADWAL",
    "KAMAREDDY",
    "KARIMNAGAR",
    "KHAMMAM",
    "KOMARAM BHEEM ASIFABAD",
    "MAHABUBABAD",
    "MAHABUBNAGAR",
    "MANCHERIAL",
    "MEDAK",
    "MEDCHAL-MALKAJGIRI",
    "MULUGU",
    "NAGARKURNOOL",
    "NALGONDA",
    "NARAYANPET",
    "NIRMAL",
    "NIZAMABAD",
    "PEDDAPALLI",
    "RAJANNA SIRCILLA",
    "RANGAREDDY",
    "SANGAREDDY",
    "SIDDIPET",
    "SURYAPET",
    "VIKARABAD",
    "WANAPARTHY",
    "WARANGAL (RURAL)",
    "WARANGAL (URBAN)",
    "YADADRI BHUVANAGIRI",
  ],
  Tripura: [
    "DHALAI",
    "GOMATI",
    "KHOWAI",
    "NORTH TRIPURA",
    "SEPAHIJALA",
    "SOUTH TRIPURA",
    "UNAKOTI",
    "WEST TRIPURA",
  ],
  "Uttar Pradesh": [
    "AGRA",
    "ALIGARH",
    "AMBEDKAR NAGAR",
    "AMETHI (CHATRAPATI SAHUJI MAHRAJ NAGAR)",
    "AMROHA (J.P. NAGAR)",
    "AURAIYA",
    "AYODHYA (FAIZABAD)",
    "AZAMGARH",
    "BAGHPAT",
    "BAHRAICH",
    "BALLIA",
    "BALRAMPUR",
    "BANDA",
    "BARABANKI",
    "BAREILLY",
    "BASTI",
    "BHADOHI",
    "BIJNOR",
    "BUDAUN",
    "BULANDSHAHR",
    "CHANDAULI",
    "CHITRAKOOT",
    "DEORIA",
    "ETAH",
    "ETAWAH",
    "FARRUKHABAD",
    "FATEHPUR",
    "FIROZABAD",
    "GAUTAM BUDDHA NAGAR",
    "GHAZIABAD",
    "GHAZIPUR",
    "GONDA",
    "GORAKHPUR",
    "HAMIRPUR",
    "HAPUR (PANCHSHEEL NAGAR)",
    "HARDOI",
    "HATHRAS",
    "JALAUN",
    "JAUNPUR",
    "JHANSI",
    "KANNAUJ",
    "KANPUR DEHAT",
    "KANPUR NAGAR",
    "KANSHIRAM NAGAR (KASGANJ)",
    "KAUSHAMBI",
    "KHERI",
    "KUSHINAGAR (PADRAUNA)",
    "LALITPUR",
    "LUCKNOW",
    "MAHARAJGANJ",
    "MAHOBA",
    "MAINPURI",
    "MATHURA",
    "MAU",
    "MEERUT",
    "MIRZAPUR",
    "MORADABAD",
    "MUZAFFARNAGAR",
    "PILIBHIT",
    "PRATAPGARH",
    "PRAYAGRAJ (ALLAHABAD)",
    "RAE BARELI",
    "RAMPUR",
    "SAHARANPUR",
    "SAMBHAL (BHIM NAGAR)",
    "SANT KABIR NAGAR",
    "SHAHJAHANPUR",
    "SHAMALI (PRABUDDH NAGAR)",
    "SHRAVASTI",
    "SIDDHARTH NAGAR",
    "SITAPUR",
    "SONBHADRA",
    "SULTANPUR",
    "UNNAO",
    "VARANASI",
  ],
  Uttarakhand: [
    "ALMORA",
    "BAGESHWAR",
    "CHAMOLI",
    "CHAMPAWAT",
    "DEHRADUN",
    "HARIDWAR",
    "NAINITAL",
    "PAURI GARHWAL",
    "PITHORAGARH",
    "RUDRAPRAYAG",
    "TEHRI GARHWAL",
    "UDHAM SINGH NAGAR",
    "UTTARKASHI",
  ],
  "West Bengal": [
    "ALIPURDUAR",
    "BANKURA",
    "BIRBHUM",
    "COOCH BEHAR",
    "DAKSHIN DINAJPUR (SOUTH DINAJPUR)",
    "DARJEELING",
    "HOOGHLY",
    "HOWRAH",
    "JALPAIGURI",
    "JHARGRAM",
    "KALIMPONG",
    "KOLKATA",
    "MALDA",
    "MURSHIDABAD",
    "NADIA",
    "NORTH 24 PARGANAS",
    "PASCHIM BARDHAMAN (WEST BARDHAMAN)",
    "PASCHIM MEDINIPUR (WEST MEDINIPUR)",
    "PURBA BARDHAMAN (EAST BARDHAMAN)",
    "PURBA MEDINIPUR (EAST MEDINIPUR)",
    "PURULIA",
    "SOUTH 24 PARGANAS",
    "UTTAR DINAJPUR (NORTH DINAJPUR)",
  ],
};
export const userList = ["Mentor", "Student"];
export const navList = {
  Mentor: [
    "/mentorcourse/1",
    "/mentorteams",
    "/tecresource",
    "/mentorpostsurvey",
    "/mentorsupport",
    "/mentorprofile",
    "/mentorchangepwd",
  ],
  Student: [
    "/studentcourse/1",
    "/instruction",
    "/studentpostsurvey",
    "/certificate",
    "/studentresource",
    // "/student-profile"
  ],
};
export const teamLength = {
  "Tamil Nadu": 5,
  default: 3,
};
// export const teamLength=[{"Tamil Nadu" :5},{default:3}];
